import React, { Component } from "react";
import "./style/step2_welcome.css";
import TermsAndConditionsModal from "./TermsAndConditionsModal";

export class Step2Welcome extends Component {
  state = {
    isModalOpen: false
  };

  handleClick = (e) => {
    if (this.props.hasAgreedToTerms) {
      this.props.nextStep();
    } else {
      alert(
        "Please confirm the conditions of participation before proceeding further."
      );
    }
  };


  switchModalOpenState = (newState) => {
    this.setState({
      isModalOpen: newState
    });
  };

  render() {
    const { isModalOpen } = this.state;
    return (
      <div className="flex-container-step2-welcome bg3">
        <label className="checkbox-with-label">
          <span className="checkbox-input">
            <input
              type="checkbox"
              name="hasAgreedToTerms"
              onChange={this.props.handleInputChange}
            />
            <span className="custom-checkbox">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                aria-hidden="true"
                focusable="false"
              >
                <path
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="3"
                  d="M1.73 12.91l6.37 6.37L22.79 4.59"
                />
              </svg>
            </span>
          </span>
          <span className="checkbox-label">
            I have read and accept the <button onClick={() => this.switchModalOpenState(true)}><u>conditions of participation.</u></button>
          </span>
        </label>
        <TermsAndConditionsModal isModalOpen={isModalOpen} switchModalOpenState={this.switchModalOpenState}></TermsAndConditionsModal>
        <div onClick={this.handleClick}>
          <button className="confirm-button-step2-welcome">Confirm</button>
        </div>
      </div>
    );
  }
}

export default Step2Welcome;
