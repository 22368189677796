import React, { Component } from "react";
import { Step0Welcome } from "./Step0Welcome";
import { Step1Welcome } from "./Step1Welcome";
import { Step2Welcome } from "./Step2Welcome";

export class WelcomeContainer extends Component {
  constructor(props) {
    super(props);
    this.state = { step: 0 };

    this.nextStep = this.nextStep.bind(this);
  }

  nextStep = () => {
    const { step } = this.state;
    this.setState({ step: step + 1 });
  };

  handleCheckboxChange = (e) => {
    this.props.handleInputChange(e.target.name, e.target.checked);
  };

  render() {
    const { step } = this.state;
    switch (step) {
      case 0:
        return <Step0Welcome nextStep={this.nextStep} />;
      case 1:
        return <Step1Welcome nextStep={this.nextStep} />;
      case 2:
        return (
          <Step2Welcome
            hasAgreedToTerms={this.props.hasAgreedToTerms}
            nextStep={this.props.nextStep}
            handleInputChange={this.handleCheckboxChange}
          />
        );
      default:
        return <h1>Error 404</h1>;
    }
  }
}

export default WelcomeContainer;
