import React, { Component } from "react";
import Step0PersonalInformation from "./Step0PersonalInformation";
import Step1PersonalInformation from "./Step1PersonalInformation";
import Step2PersonalInformation from "./Step2PersonalInformation";
import { getCsrfToken } from "../../../util";

export class PersonalInformationContainer extends Component {
  state = { step: 0, countries: [] };

  async populateCountriesArray() {
    const res = await fetch("./countries.json", {
      method: "GET",
      headers: {
        'X-CSRFToken': getCsrfToken()
      },
    }).catch((err) => console.error("Couldn't read countries data", err));
    const data = await res.json();

    let filteredCountries = data.map((entry) => entry["Name"]);
    this.setState({ countries: filteredCountries });
  }

  componentDidMount() {
    this.populateCountriesArray();
  }

  parseInteger = (int) => {
    if (int === "") {
      return 0;
    }
    return parseInt(int);
  };

  nextStep = () => {
    const { step } = this.state;
    this.setState({ step: step + 1 });
  };

  increaseAge = () => {
    let newAge = this.parseInteger(this.props.age) + 1;
    this.props.handleInputChange("age", newAge);
  };

  decreaseAge = (prevState) => {
    let currentAge = this.parseInteger(this.props.age);
    if (currentAge === 0 || currentAge > 150) {
      return;
    }
    let newAge = this.parseInteger(currentAge) - 1;
    this.props.handleInputChange("age", newAge);
  };

  handleInputChange = (e) => {
    if (e.target.name === "age") {
      let newAge = this.parseInteger(e.target.value);
      if (newAge < 0 || newAge > 150) {
        return;
      }
    }

    let name = e.target.name;
    let value = e.target.value;

    if (e.target.localName == "li") {
      name = "residencyPlace";
      value = e.target.innerHTML;
    }

    this.props.handleInputChange(name, value);
  };

  validatePersonalInformation = () => {
    if (this.props.age < 18) {
      alert(
        "Please participate in the survey only if you are 18 years old or older.\nEnter a valid age."
      );
      return;
    }
    const personalInformation = [
      this.props.residencyPlace,
      this.props.gender,
      this.props.age
    ];
    for (const value of personalInformation) {
      if (value === "" || value === undefined) {
        alert("Please fill all form elements.");
        return;
      }
    }
    this.nextStep();
  };

  validateEmail = () => {
    var re = /\S+@\S+\.\S+/;
    if (!re.test(this.props.email)) {
      alert("Please enter a valid Email.");
      return;
    }
    this.props.registerAccount();
  };

  render() {
    const { step, countries } = this.state;
    switch (step) {
      case 0:
        return <Step0PersonalInformation nextStep={this.nextStep} />;
      case 1:
        return (
          <Step1PersonalInformation
            age={this.props.age}
            residencyPlace={this.props.residencyPlace}
            gender={this.props.gender}
            countries={countries}
            validatePersonalInformation={this.validatePersonalInformation}
            increaseAge={this.increaseAge}
            decreaseAge={this.decreaseAge}
            handleInputChange={this.handleInputChange}
          />
        );
      case 2:
        return (
          <Step2PersonalInformation
            email={this.props.email}
            validateEmail={this.validateEmail}
            handleInputChange={this.handleInputChange}
          />
        );
      default:
        return <h1>Error 404</h1>;
    }
  }
}

export default PersonalInformationContainer;
