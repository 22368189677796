import React, { Component } from 'react';
import "../style/scale_question.css";

export class ScaleQuestion extends Component {
  changeScaleValue = (e) => {
    if (e.target.value != null) {
      const questionAbbreviation = this.props.questionAbbreviation;
      this.props.handleInputChange(questionAbbreviation, e.target.value);
      this.props.nextStep();
    }
  };

  render() {
    return (
      <div className="container bg4">
        <div className="">
          <div className='main-text'>{this.props.mainQuestion}</div>
          <div className="sub-text">{this.props.subQuestion ? <ul><li><p>{this.props.subQuestion}</p></li></ul> : <div><br /> </div>} </div>

          <div className='scale-question-second-container'>
            <div className='rate-options-container'>
              <div className='rate-description-container'>
                <button
                  className="rate-option"
                  onClick={this.changeScaleValue}
                  value="1"
                >
                  1
                </button>
                <p className='rate-description'>{this.props.TTM1}</p>
              </div>
              <div className='rate-description-container'>
                <button
                  className="rate-option"
                  onClick={this.changeScaleValue}
                  value="2"
                >
                  2
                </button>
                <p className='rate-description'>{this.props.TTM2}</p>
              </div>
              <div className='rate-description-container'>
                <button
                  className="rate-option "
                  onClick={this.changeScaleValue}
                  value="3"
                >
                  3
                </button>
                <p className='rate-description'>{this.props.TTM3}</p>
              </div>
              <div className='rate-description-container'>
                <button
                  className="rate-option "
                  onClick={this.changeScaleValue}
                  value="4"
                >
                  4
                </button>
                <p className='rate-description'>{this.props.TTM4}</p>
              </div>
              <div className='rate-description-container'>
                <button
                  className="rate-option"
                  onClick={this.changeScaleValue}
                  value="5"
                >
                  5
                </button>
                <p className='rate-description'>{this.props.TTM5}</p>
              </div>
            </div>
          </div>
          <div className={` robot-div-scale-question ${this.props.robot} `}></div>
        </div>

      </div>
    );
  }
}

export default ScaleQuestion;
