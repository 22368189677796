import React, { Component } from 'react';
import { mainQuestionProcess, subQuestionCc, subQuestionCr, subQuestionDe, subQuestionHr, subQuestionSr } from './util/questions';
import ScaleQuestion from './util/ScaleQuestion';

export class ProcessContainer extends Component {
    state = {
        step: 0,
        submitDisabled: false,
        TTM1: "Never",
        TTM2: "Seldom",
        TTM3: "Occasionally",
        TTM4: "Often",
        TTM5: "Repeatedly"
    };

    nextStep = () => {
        const { step } = this.state;
        this.setState({
            step: step + 1
        });
    };

    handleClick = () => {
        const { submitDisabled } = this.state;
        if (!submitDisabled) {
            this.setState({
                submitDisabled: true
            });
        }
    };

    render() {
        const { step, TTM1, TTM2, TTM3, TTM4, TTM5 } = this.state;
        switch (step) {
            case 0:
                return <ScaleQuestion key="sr" nextStep={this.nextStep} handleInputChange={this.props.handleInputChange}
                    questionAbbreviation="sr" mainQuestion={"5. " + mainQuestionProcess} subQuestion={subQuestionSr} robot="arms-high-up-robot-cropped"
                    TTM1={TTM1} TTM2={TTM2} TTM3={TTM3} TTM4={TTM4} TTM5={TTM5} />;
            case 1:
                return <ScaleQuestion key="de" nextStep={this.nextStep} handleInputChange={this.props.handleInputChange}
                    questionAbbreviation="de" mainQuestion={"6. " + mainQuestionProcess} subQuestion={subQuestionDe} robot="shy-smile-robot-cropped"
                    TTM1={TTM1} TTM2={TTM2} TTM3={TTM3} TTM4={TTM4} TTM5={TTM5} />;
            case 2:
                return <ScaleQuestion key="cc" nextStep={this.nextStep} handleInputChange={this.props.handleInputChange}
                    questionAbbreviation="cc" mainQuestion={"7. " + mainQuestionProcess} subQuestion={subQuestionCc} robot="just-friendly-robot-cropped"
                    TTM1={TTM1} TTM2={TTM2} TTM3={TTM3} TTM4={TTM4} TTM5={TTM5} />;
            case 3:
                return <ScaleQuestion key="hr" nextStep={this.nextStep} handleInputChange={this.props.handleInputChange}
                    questionAbbreviation="hr" mainQuestion={"8. " + mainQuestionProcess} subQuestion={subQuestionHr} robot="hello-smile-robot-cropped"
                    TTM1={TTM1} TTM2={TTM2} TTM3={TTM3} TTM4={TTM4} TTM5={TTM5} />;
            case 4:
                return <ScaleQuestion key="cr" nextStep={this.handleClick} handleInputChange={this.props.handleInputChange}
                    questionAbbreviation="cr" mainQuestion={"9. " + mainQuestionProcess} subQuestion={subQuestionCr} robot="arms-high-up-robot-cropped"
                    TTM1={TTM1} TTM2={TTM2} TTM3={TTM3} TTM4={TTM4} TTM5={TTM5} />;
            default:
                console.error("[IllegalState]: 'Step' state has a value bigger than four in ProcessContainer.");
        }
    }
}

export default ProcessContainer;
