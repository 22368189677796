import React, { Component } from "react";
import PersonalInformationContainer from "./personal_information/PersonalInformationContainer";
import WelcomeContainer from "./welcome/WelcomeContainer";
import { getCsrfToken } from "../../util";

export class UserInformationStepsHandler extends Component {
  state = {
    step: 0,
    hasAgreedToTerms: false,
    email: "",
    age: 18,
    gender: "",
    residencyPlace: ""
  };

  // Proceed to next step
  nextStep = () => {
    const { step } = this.state;
    this.setState({
      step: step + 1
    });
  };

  // Go back to prev step
  prevStep = () => {
    const { step } = this.state;
    this.setState({
      step: step - 1
    });
  };

  // Handle field change
  handleInputChange = (key, value) => {
    this.setState({
      [key]: value
    });
  };

  registerAccount = () => {
    const { hasAgreedToTerms, email, age, gender, residencyPlace } = this.state;

    // POST request using fetch with error handling
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        'X-CSRFToken': getCsrfToken()
      },
      body: JSON.stringify({
        has_agreed_to_terms: hasAgreedToTerms,
        email: email,
        age: age,
        gender: gender,
        residency_place: residencyPlace
      })
    };

    fetch(`${process.env.REACT_APP_BACKEND_DOMAIN}/register`, requestOptions)
      .then(async (response) => {
        const isJson = response.headers
          .get("content-type")
          ?.includes("application/json");
        const data = isJson && (await response.json());
        // check for error response
        if (!response.ok) {
          // get error message from body or default to response status
          const error = data && data.message;
          const errorInformation = {
            error: error,
            data: data,
            statusCode: response.status
          };
          return Promise.reject(errorInformation);
        }
        this.props.setUserId(data.id);
      })
      .catch((errorInformation) => {
        console.error("There was an error!", errorInformation.error);
        console.error(
          "Response data that resulted in error: ",
          errorInformation.data
        );
        if (errorInformation.data.email) {
          alert(errorInformation.data.email);
        }
        this.setState({ ...errorInformation });
      });
  };

  render() {
    const { step, hasAgreedToTerms, email, age, residencyPlace, gender } =
      this.state;
    switch (step) {
      case 0:
        return (
          <WelcomeContainer
            hasAgreedToTerms={hasAgreedToTerms}
            nextStep={this.nextStep}
            handleInputChange={this.handleInputChange}
          />
        );
      case 1:
        return (
          <PersonalInformationContainer
            age={age}
            residencyPlace={residencyPlace}
            gender={gender}
            email={email}
            nextStep={this.nextStep}
            handleInputChange={this.handleInputChange}
            registerAccount={this.registerAccount}
          />
        );
      default:
        return <h1>Error 404</h1>;
    }
  }
}

export default UserInformationStepsHandler;
