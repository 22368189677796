import React, { Component } from 'react';
import { mainQuestionPrCo, subQuestionCo, subQuestionPr } from './util/questions';
import ScaleQuestion from './util/ScaleQuestion';

export class ProsConsContainer extends Component {
    state = {
        step: 0,
        TTM1: "Not important",
        TTM2: "Slightly important",
        TTM3: "Moderately important",
        TTM4: "Very important",
        TTM5: "Extremely important"
    };

    nextStep = () => {
        const { step } = this.state;
        this.setState({
            step: step + 1
        });
    };

    render() {
        const { step, TTM1, TTM2, TTM3, TTM4, TTM5 } = this.state;

        switch (step) {
            case 0:
                return <ScaleQuestion key="pros" nextStep={this.nextStep} handleInputChange={this.props.handleInputChange}
                    questionAbbreviation="pros" mainQuestion={"2. " + mainQuestionPrCo} subQuestion={subQuestionPr} robot="just-friendly-robot-cropped"
                    TTM1={TTM1} TTM2={TTM2} TTM3={TTM3} TTM4={TTM4} TTM5={TTM5} />;
            case 1:
                return <ScaleQuestion key="cons" nextStep={this.props.nextQuestion} handleInputChange={this.props.handleInputChange}
                    questionAbbreviation="cons" mainQuestion={"3. " + mainQuestionPrCo} subQuestion={subQuestionCo} robot="shy-smile-robot-cropped"
                    TTM1={TTM1} TTM2={TTM2} TTM3={TTM3} TTM4={TTM4} TTM5={TTM5} />;
            default:
                console.error("[IllegalState]: 'Step' state has a value bigger than one in ProsConsContainer.");
        }
    }
}

export default ProsConsContainer;
