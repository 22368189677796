import React, { Component } from "react";
import "./style/stage_determination_question.css";

export class StageDeterminationQuestion extends Component {
  render() {
    return (
      <div className="flex-container-first-question bg5">
        <div className="enthusiastic-robot robot-div-first-question"></div>
        <div className="p-first-question">
          <p>1. Do you do regular physical activity ?</p>
        </div>
        <div className="ul-div-first-question">
          <ul>
            <div>
              <span />
              <li>Should be at least 150 minutes per week.</li>
            </div>
            <div>
              <span />
              <li>
                Should be enough to make your heart beat faster and/or make you
                breathe harder.
              </li>
            </div>
            <div>
              <span />
              <li>
                Examples are <b>brisk walking</b>, <b>biking</b>,{" "}
                <b>swimming</b>, <b>soccer</b> or <b>aerobic classes.</b>
              </li>
            </div>
          </ul>
        </div>
        <div className="p-first-question">
          <p>Please check ONE of the five answers below:</p>
        </div>

        <div className="item-5-first-question">
          <label className="checkbox-with-label-first-question">
            <span className="checkbox-input-first-question">
              <input
                type="checkbox"
                value="0"
                onChange={e => this.props.setStage(e.target.value)}
                checked={this.props.stage === "0"}
              />
              <span className="custom-checkbox-first-question">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  aria-hidden="true"
                  focusable="false"
                >
                  <path
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="3"
                    d="M1.73 12.91l6.37 6.37L22.79 4.59"
                  />
                </svg>
              </span>
            </span>
            <span className="checkbox-label-first-question">
              No, and I do not plan to start regular physical activity in the
              next 6 months.
            </span>
          </label>

          <label className="checkbox-with-label-first-question">
            <span className="checkbox-input-first-question">
              <input
                type="checkbox"
                value="1"
                onChange={e => this.props.setStage(e.target.value)}
                checked={this.props.stage === "1"}
              />
              <span className="custom-checkbox-first-question">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  aria-hidden="true"
                  focusable="false"
                >
                  <path
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="3"
                    d="M1.73 12.91l6.37 6.37L22.79 4.59"
                  />
                </svg>
              </span>
            </span>
            <span className="checkbox-label-first-question">
              No, but I plan to start regular physical activity in the next 6
              months.
            </span>
          </label>

          <label className="checkbox-with-label-first-question">
            <span className="checkbox-input-first-question">
              <input
                type="checkbox"
                value="2"
                onChange={e => this.props.setStage(e.target.value)}
                checked={this.props.stage === "2"}
              />
              <span className="custom-checkbox-first-question">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  aria-hidden="true"
                  focusable="false"
                >
                  <path
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="3"
                    d="M1.73 12.91l6.37 6.37L22.79 4.59"
                  />
                </svg>
              </span>
            </span>
            <span className="checkbox-label-first-question">
              No, but I plan to start regular physical activity in the next 30
              days.
            </span>
          </label>

          <label className="checkbox-with-label-first-question">
            <span className="checkbox-input-first-question">
              <input
                type="checkbox"
                value="3"
                onChange={e => this.props.setStage(e.target.value)}
                checked={this.props.stage === "3"}
              />
              <span className="custom-checkbox-first-question">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  aria-hidden="true"
                  focusable="false"
                >
                  <path
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="3"
                    d="M1.73 12.91l6.37 6.37L22.79 4.59"
                  />
                </svg>
              </span>
            </span>
            <span className="checkbox-label-first-question">
              Yes, I have been for less than 6 months.
            </span>
          </label>

          <label className="checkbox-with-label-first-question">
            <span className="checkbox-input-first-question">
              <input
                type="checkbox"
                value="4"
                onChange={e => this.props.setStage(e.target.value)}
                checked={this.props.stage === "4"}
              />
              <span className="custom-checkbox-first-question">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  aria-hidden="true"
                  focusable="false"
                >
                  <path
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="3"
                    d="M1.73 12.91l6.37 6.37L22.79 4.59"
                  />
                </svg>
              </span>
            </span>
            <span className="checkbox-label-first-question">
              Yes, I have been for more than 6 months.
            </span>
          </label>
        </div>
        <div
          className="confirm-button-container"
          onClick={this.props.nextStep}
        >
          <button className="confirm-button-div-first-question">Confirm</button>
        </div>
      </div>
    );
  }
}

export default StageDeterminationQuestion;
