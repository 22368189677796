import React, { Component } from "react";
import { AutoComplete } from "../../util/AutoComplete";

import "./style/step1_personal_information.css";

export class Step1PersonalInformation extends Component {
  render() {
    return (
      <div className="flex-container-step1-personal-information bg4">
        <div className="item-1">
          <div>
            <h1 className="main-text-step1-personal-information">
              1. How old are you ?
            </h1>
          </div>
          <div>
            <div>
              <input
                className="input-step1-personal-information"
                type="number"
                name="age"
                min="5"
                value={this.props.age}
                placeholder="18"
                onChange={this.props.handleInputChange}
              />
            </div>
            <div>
              <div>
                <button
                  className="plus-minus-button-step1-personal-information"
                  onClick={this.props.increaseAge}
                >
                  +
                </button>
              </div>
              <div>
                <button
                  className="plus-minus-button-step1-personal-information "
                  onClick={this.props.decreaseAge}
                >
                  -
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="item-2">
          <div>
            <h1 className="main-text-step1-personal-information">
              2. What is your gender ?
            </h1>
          </div>
          <div>
            <div>
              <label className="checkbox-with-label-step1-personal-information ">
                <span className="checkbox-input">
                  <input
                    id="male"
                    type="radio"
                    name="gender"
                    value="MALE"
                    checked={this.props.gender === "MALE"}
                    onChange={this.props.handleInputChange}
                  />
                  <span className="custom-checkbox">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      aria-hidden="true"
                      focusable="false"
                    >
                      <path
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="3"
                        d="M1.73 12.91l6.37 6.37L22.79 4.59"
                      />
                    </svg>
                  </span>
                </span>
                <span className="checkbox-label-step1-personal-information">
                  Male
                </span>
              </label>
            </div>
            <div>
              <label className="checkbox-with-label-step1-personal-information ">
                <span className="checkbox-input">
                  <input
                    id="female"
                    type="radio"
                    name="gender"
                    value="FEMALE"
                    checked={this.props.gender === "FEMALE"}
                    onChange={this.props.handleInputChange}
                  />
                  <span className="custom-checkbox">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      aria-hidden="true"
                      focusable="false"
                    >
                      <path
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="3"
                        d="M1.73 12.91l6.37 6.37L22.79 4.59"
                      />
                    </svg>
                  </span>
                </span>
                <span className="checkbox-label-step1-personal-information">
                  Female
                </span>
              </label>
            </div>
            <div>
              <label className="checkbox-with-label-step1-personal-information ">
                <span className="checkbox-input">
                  <input
                    id="other"
                    type="radio"
                    name="gender"
                    value="OTHER"
                    checked={this.props.gender === "OTHER"}
                    onChange={this.props.handleInputChange}
                  />
                  <span className="custom-checkbox">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      aria-hidden="true"
                      focusable="false"
                    >
                      <path
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="3"
                        d="M1.73 12.91l6.37 6.37L22.79 4.59"
                      />
                    </svg>
                  </span>
                </span>
                <span className="checkbox-label-step1-personal-information">
                  Other
                </span>
              </label>
            </div>
          </div>
        </div>
        <div className="item-3">
          <div>
            <h1 className="main-text-step1-personal-information">
              3. What country do you live in?
            </h1>
          </div>
          <div className="country-suggestions-container">
            <AutoComplete
              name="residencyPlace"
              suggestions={this.props.countries}
              handleInputChange={this.props.handleInputChange}
            />
          </div>
        </div>
        <div className="item-4">
          <button
            className="confirm-button-step1-personal-information"
            onClick={this.props.validatePersonalInformation}
          >
            Confirm
          </button>
        </div>
        <div className="robot-div-style-step1-personal-information just-friendly-robot"></div>
      </div>
    );
  }
}

export default Step1PersonalInformation;
