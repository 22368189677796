import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Component } from 'react';
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import './style/report.css';

export class Report extends Component {
    render() {
        const { first, firstIcons, second, third, thirdIcons, fourth, fifth } = this.props.paragraphs;
        return (
            <div className="report-container bg1">
                <div className="report-introduction">
                    <p>
                        Thank you for your answers! Here is your feedback.<br />
                    </p>
                </div>
                <div className='text-container'>
                    <div className='paragraphs-container'>
                        {first ? (<div className="paragraph">
                            <li>
                                {first}
                            </li>
                            <div className="follow-up-fontawesome-icons-container">
                                {firstIcons.map((c) => <div key={c} className={c}></div>)}
                            </div>
                        </div>) : null}
                        {second ? (<div className="paragraph">
                            <li>
                                {second}
                            </li>
                        </div>) : null}
                        {third ? (<div className="paragraph">
                            <li>
                                {third}
                            </li>
                            <div className="follow-up-fontawesome-icons-container">
                                {thirdIcons.map((c) => <div key={c} className={c}></div>)}
                            </div>
                        </div>) : null}
                        {fourth ? (<div className="paragraph">
                            <li>
                                {fourth}
                            </li>
                        </div>) : null}
                        {fifth ? (<div className="paragraph">
                            <li>
                                {fifth}
                            </li>
                        </div>) : null}
                    </div>
                </div>

                <div className="next-icon-div-step0-survey-end">
                    <button>
                        <FontAwesomeIcon
                            className="icon-report"
                            icon={faChevronRight}
                            size="2x"
                            onClick={this.props.nextStep}
                        />
                    </button>
                </div>
            </div>
        );
    }
}

export default Report;