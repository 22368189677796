/* Main questions */
export const mainQuestionPrCo = "How important are the following opinions in your decision to do physical activity or not?";
export const mainQuestionSe = "How confident you are that you can be physically active for a total of 150 minutes per week?";
export const mainQuestionProcess = "How often over the past month you did the following:";

/* Sub questions */
export const subQuestionPr = "Physical activity is good for you.";
export const subQuestionCo = "Physical activity is bad for you.";
export const subQuestionCr = "You look for information related to physical activity.";
export const subQuestionHr = "You have someone who encourages you to do physical activity.";
export const subQuestionCc = "You take a walk or exercise, instead of relaxing by watching TV or eating.";
export const subQuestionDe = "You realize that if you are not regularly physically active, you will feel bad.";
export const subQuestionSr = "You feel better about yourself when you do physical activity.";
