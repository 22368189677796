import React, { Component } from 'react';
import { mainQuestionSe } from './util/questions';
import ScaleQuestion from './util/ScaleQuestion';

export class SelfEfficacyContainer extends Component {
    state = {
        step: 0,
        TTM1: "Not at all confident",
        TTM2: "Not very confident",
        TTM3: "Moderately confident",
        TTM4: "Very confident",
        TTM5: "Completely confident"
    };

    render() {
        const { step, TTM1, TTM2, TTM3, TTM4, TTM5 } = this.state;
        switch (step) {
            case 0:
                return <ScaleQuestion key="self_efficacy" nextStep={this.props.nextQuestion} handleInputChange={this.props.handleInputChange}
                    questionAbbreviation="self_efficacy" mainQuestion={"4. " + mainQuestionSe} subQuestion="" robot="just-friendly-robot-cropped"
                    TTM1={TTM1} TTM2={TTM2} TTM3={TTM3} TTM4={TTM4} TTM5={TTM5} />;
            default:
                console.error("[IllegalState]: 'Step' state has a value bigger than zero in SelfEfficacyContainer.");
        }
    }
}

export default SelfEfficacyContainer;
