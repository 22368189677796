import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import "./style/step0_welcome.css";


export class Step0Welcome extends Component {
  render() {
    return (
      <div className="flex-container-step0-welcome bg1">
        <div className="p-step0-welcome">
          <p>
            <span className="span-step0-welcome">HELLO YOU!</span>
            <br />
            Welcome to this project about regular physical activity.
          </p>
        </div>
        <div className="item2-step0-welcome">
          <FontAwesomeIcon
            className="icon-step0-welcome"
            icon={faChevronRight}
            size="2x"
            onClick={this.props.nextStep}
          />
        </div>
        <div className="hello-smile-robot robot-div-style-step0-welcome"></div>
      </div>
    );
  }
}

export default Step0Welcome;
