import React, { Component } from "react";
import "./style/good_bye.css";

export class GoodBye extends Component {

  render() {
    return (
      <div className="flex-container-goodbye bg1">
        <div className="p-goodbye">
          <p><span>Thank you for participating </span>{!this.props.seeYouNextTime ? <span> - we will email you again in a week.</span> : null}
            <br />
            <span className="span-step0-welcome">Stay safe and active :)</span></p>
        </div>
        <div className="hello-smile-robot robot-div-style-goodbye "></div>
      </div>
    );
  }
}

export default GoodBye;
