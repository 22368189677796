import React, { Component } from "react";
import UserInformationStepsHandler from "./user_information/UserInformationStepsHandler";
import QuestionsContainer from "./questions/QuestionsContainer";
import { getCsrfToken } from "../util";
import GoodBye from "./util/GoodBye";
import Report from "./util/Report";
import StageDeterminationReportContainer from "./questions/stage_determination/StageDeterminationReportContainer";

export class StepsHandler extends Component {
    state = {
        step: 0,
        firstQuestionAnswer: "",
        firstQuestionOldAnswer: undefined,
        userId: "",
        token: "",
        validatingToken: false,
        isTokenValid: false,
        isFollowUp: false,
        seeYouNextTime: false,
        paragraphs: {},
        stage: null,
        last_repetition: false
    };

    componentDidMount() {
        const { search } = window.location;
        const match = search?.match(/token=(.*)/);
        const token = match?.[1];
        if (token) {
            this.setState({
                isFollowUp: true,
                seeYouNextTime: true,
                token: token,
                validatingToken: true,
                step: 1
            });
            this.validateToken(token);
        }
    }

    validateToken(token) {
        const requestOptions = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                'X-CSRFToken': getCsrfToken()
            },
            body: JSON.stringify({
                token: token
            })
        };

        fetch(
            `${process.env.REACT_APP_BACKEND_DOMAIN}/validate-token`,
            requestOptions
        )
            .then(async (response) => {
                const isJson = response.headers
                    .get("content-type")
                    ?.includes("application/json");
                const data = isJson && (await response.json());
                // check for error response
                if (!response.ok) {
                    // get error message from body or default to response status
                    const error = data && data.message;
                    const errorInformation = {
                        error: error,
                        data: data,
                        statusCode: response.status
                    };
                    return Promise.reject(errorInformation);
                }

                this.setState({
                    validatingToken: false,
                    isTokenValid: true,
                    userId: data.id,
                    firstQuestionOldAnswer: data.firstQuestionOldAnswer
                });
            })
            .catch((errorInformation) => {
                console.error("There was an error!", errorInformation.error);
                this.setState({ ...errorInformation, validatingToken: false });
            });
    }

    nextStep = () => {
        const { step } = this.state;
        if (step < 4) {
            this.setState({
                step: step + 1
            });
        }
    };

    setFollowUpFalse = () => {
        this.setState({
            isFollowUp: false
        });
    };

    setUserId = (userId) => {
        this.setState({
            userId: userId
        });
        this.nextStep();
    };

    answerQuestion = (question, answer) => {
        this.setState({
            [question]: answer
        });
    };

    setParagraphs = (paragraphs) => {
        this.setState({
            paragraphs: paragraphs,
        });
    };

    setStage = (stage) => {
        this.setState({
            stage: stage
        });
    };

    render() {
        const {
            step,
            userId,
            firstQuestionOldAnswer,
            token,
            validatingToken,
            isTokenValid,
            isFollowUp,
            seeYouNextTime,
            paragraphs,
            stage
        } = this.state;
        switch (step) {
            case 0:
                return <UserInformationStepsHandler setUserId={this.setUserId} />;
            case 1:
                return (
                    <QuestionsContainer
                        nextStep={this.nextStep}
                        userId={userId}
                        answerQuestion={this.answerQuestion}
                        setUserId={this.setUserId}
                        token={token}
                        isTokenValid={isTokenValid}
                        validatingToken={validatingToken}
                        isFollowUp={isFollowUp}
                        setFollowUpFalse={this.setFollowUpFalse}
                        firstQuestionOldAnswer={firstQuestionOldAnswer}
                        setParagraphs={this.setParagraphs}
                        setStage={this.setStage}
                        stage={stage}
                    />
                );
            case 2:
                return (
                    <StageDeterminationReportContainer
                        stage={stage}
                        firstQuestionOldAnswer={firstQuestionOldAnswer}
                        nextStep={this.nextStep}
                    />
                );
            case 3:
                return <Report paragraphs={paragraphs} nextStep={this.nextStep} />;
            case 4:
                return <GoodBye seeYouNextTime={seeYouNextTime} />;
            default:
                return <h1>Error 404</h1>;
        }
    }
}

export default StepsHandler;
