import React, { Component } from "react";
import WelcomeBack from "./util/WelcomeBack";
import InvalidToken from "./util/InvalidToken";
import { getCsrfToken } from "../../util";
import ProsConsContainer from "./ProsConsContainer";
import ProcessContainer from "./ProcessContainer";
import SelfEfficacyContainer from "./SelfEfficacyContainer";
import StageDeterminationQuestion from "./stage_determination/StageDeterminationQuestion";

export class QuestionsContainer extends Component {
    state = {
        step: 0,
        pros: null,
        cons: null,
        self_efficacy: null,
        sr: null,
        de: null,
        cc: null,
        hr: null,
        cr: null,
        submitting: false
    };

    nextStep = () => {
        const { step } = this.state;
        this.setState({
            step: step + 1
        });
    };

    handleInputChange = (name, value) => {
        if (name == "cr") {
            this.setState({
                [name]: value
            }, this.submitAnswer);
        }
        else {
            this.setState({
                [name]: value
            });
        }
    };

    submitAnswer = () => {
        const { pros, cons, self_efficacy, sr, de, cc, hr, cr } = this.state;
        if (this.props.stage == undefined || this.props.stage == null || this.props.stage.length == 0) {
            alert("Please choose a stage.")
            return
        }

        const requestOptions = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                'X-CSRFToken': getCsrfToken()
            },
            body: JSON.stringify({
                user_id: this.props.userId,
                stage: this.props.stage,
                variables: {
                    pros: pros,
                    cons: cons,
                    self_efficacy: self_efficacy,
                    sr: sr,
                    de: de,
                    cc: cc,
                    hr: hr,
                    cr: cr
                }
            })
        };

        fetch(`${process.env.REACT_APP_BACKEND_DOMAIN}/submit`, requestOptions)
            .then(async (response) => {
                const isJson = response.headers
                    .get("content-type")
                    ?.includes("application/json");
                const data = isJson && (await response.json());
                // check for error response
                if (!(response.status == 200)) {
                    // get error message from body or default to response status
                    const error = data && data.message;
                    const errorInformation = {
                        error: error,
                        data: data,
                        statusCode: response.status
                    };

                    return Promise.reject(errorInformation);
                }

                this.props.setParagraphs(data.paragraphs);
                this.props.nextStep();
            })
            .catch((errorInformation) => {
                console.error("There was an error!", errorInformation.error);
                this.setState({ ...errorInformation });
            });
    };

    render() {
        const { step } = this.state;

        if (this.props.isFollowUp) {
            if (this.props.isTokenValid || this.props.validatingToken) {
                return <WelcomeBack setFollowUpFalse={this.props.setFollowUpFalse} />;
            }
            return <InvalidToken />;
        }
        if (!this.props.stage || step == 0) {
            return (
                <StageDeterminationQuestion
                    stage={this.props.stage}
                    setStage={this.props.setStage}
                    nextStep={this.nextStep}
                />
            );
        }

        else {
            switch (step) {
                case 1:
                    return <ProsConsContainer handleInputChange={this.handleInputChange} nextQuestion={this.nextStep} />
                case 2:
                    return <SelfEfficacyContainer handleInputChange={this.handleInputChange} nextQuestion={this.nextStep} />
                case 3:
                    return <ProcessContainer handleInputChange={this.handleInputChange} submitAnswer={this.submitAnswer} />
            }
        }
    }
}

export default QuestionsContainer;
