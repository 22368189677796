export function getCsrfToken() {
    var cookieValue = null;
    if (document.cookie && document.cookie !== '') {
        var cookies = document.cookie.split(';');
        if (cookies) {
            for (var i = 0; i < cookies.length; i++) {
                var cookie = cookies[i].trim().replace(/^([\s]*)|([\s]*)$/g, "");
                if (cookie.substring(0, 'csrftoken'.length + 1) === ('csrftoken' + '=')) {
                    cookieValue = decodeURIComponent(cookie.substring('csrftoken'.length + 1));
                    break;
                }
            }
        }
    }
    return cookieValue;
}