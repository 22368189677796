import React, { Component } from "react";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./style/step2_personal_information.css";

export class Step2PersonalInformation extends Component {
  render() {
    return (
      <div className="flex-container-step2-personal-information bg3">
        <div>
          <p className="p-step2-personal-information">
            We also need your Email-address to send you the link for the second
            part of the study.
          </p>
        </div>
        <div className="robot-div-style-step2-personal-information just-friendly-robot"></div>
        <div>
          <div>
            <div className="email-icon-step2-personal-information">
              <FontAwesomeIcon icon={faEnvelope} size="1x" />
            </div>
            <div className="email-input-container">
              <input
                className="input-step2-personal-information"
                type="email"
                name="email"
                placeholder="Your E-Mail Address"
                onChange={this.props.handleInputChange}
              />
            </div>
          </div>
        </div>
        <div className="div-confirm-button">
          <button
            className="confirm-button-step2-personal-information"
            onClick={this.props.validateEmail}
          >
            Confirm
          </button>
        </div>
      </div>
    );
  }
}

export default Step2PersonalInformation;
