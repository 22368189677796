import React, { Component } from "react";

export class InvalidToken extends Component {
  render() {
    return (
      <div className="flex-container-step0-personal-information bg2">
        <div className="p-step0-personal-information">
          <p>
            Oops!
            <br />
            It seems like the link is outdated.
          </p>
        </div>
        <div className="shy-smile-robot robot-div-style-step0-personal-information"></div>
      </div>
    );
  }
}

export default InvalidToken;
