import React, { Component } from 'react'
import Modal from 'react-modal';

export default class TermsAndConditionsModal extends Component {

    render() {
        return (
            <Modal
                style={{
                    overlay: {
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        backgroundColor: 'rgb(255 255 255 / 0%)'
                    },
                    content: {
                        position: 'absolute',
                        top: '50vh',
                        left: '50vw',
                        transform: 'translate(-50%, -50%)',
                        border: '1px solid #ccc',
                        background: 'rgb(184 249 173)',
                        overflow: 'auto',
                        WebkitOverflowScrolling: 'touch',
                        width: '75vw',
                    }
                }}
                isOpen={this.props.isModalOpen}
                onRequestClose={() => this.props.switchModalOpenState(false)}
            >
                <div className="terms-and-conditions-p">
                    <p>By participating, I understand that:</p>
                </div>
                <div className="terms-and-conditions-ul-container">
                    <ul>
                        <div>
                            <span />
                            <li>I voluntarily agree to take part in this project.</li>
                        </div>
                        <div>
                            <span />
                            <li>I can withdraw at any time or refuse to answer any question without any negative consequences.</li>
                        </div>
                        <div>
                            <span />
                            <li>
                                I can withdraw permission to use my data within two weeks after the project.
                            </li>
                        </div>
                        <div>
                            <span />
                            <li>
                                Participation involves answering a few questions and receiving feedback related to physical activity at each time point of the project.
                            </li>
                        </div>

                        <div>
                            <span />
                            <li>
                                There are no foreseeable risks and I may benefit by becoming more physically active.
                            </li>
                        </div>

                        <div>
                            <span />
                            <li>iknu
                                All information I provide for this project will be treated confidentially.
                            </li>
                        </div>
                    </ul>
                </div>
            </Modal >
        )
    }
}
