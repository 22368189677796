import React, { Component } from "react";
import StageDeterminationReport from "./StageDeterminationReport";
import StageDeterminationReportFollowUp from "./StageDeterminationReportFollowUp";

const followUpOptions = {
    PA_PC_N: 0,
    PA_CO_N: 1,
    PA_CO_P: 2,
    PA_PR_N: 3,
    PA_PR_P: 4,
    PA_AC_N: 5,
    PA_AC_P: 6,
    PA_MA_P: 7
};

export class StageDeterminationReportContainer extends Component {
    calculateFollowUpResult(oldAnswer, newAnswer) {
        switch (newAnswer) {
            case 0:
                return followUpOptions.PA_PC_N;
            case 1:
                if (oldAnswer >= newAnswer) {
                    return followUpOptions.PA_CO_N;
                } else {
                    return followUpOptions.PA_CO_P;
                }
            case 2:
                if (oldAnswer >= newAnswer) {
                    return followUpOptions.PA_PR_N;
                } else {
                    return followUpOptions.PA_PR_P;
                }
            case 3:
                if (oldAnswer > newAnswer) {
                    return followUpOptions.PA_AC_N;
                } else {
                    return followUpOptions.PA_AC_P;
                }
            case 4:
                return followUpOptions.PA_MA_P;
        }
    }

    nextStep = () => {
        const { step } = this.state;
        this.setState({
            step: step + 1
        });
    };

    render() {
        if (this.props.lastTimeAnswer != undefined) {
            const followUpResult = this.calculateFollowUpResult(
                parseInt(this.props.lastTimeAnswer),
                parseInt(this.props.stage)
            );
            return (< StageDeterminationReportFollowUp followUpResult={followUpResult}
                nextStep={this.props.nextStep}
            />
            );
        } else {
            return (< StageDeterminationReport stage={this.props.stage}
                nextStep={this.props.nextStep}
            />
            );
        }

    }
}

export default StageDeterminationReportContainer;