import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";

import "./style/stage_determination_report.css";

export class StageDeterminationReport extends Component {
  render() {
    const stage = parseInt(this.props.stage);
    switch (stage) {
      case 0:
        return (
          <div className="flex-container-step0-survey-end bg1">
            <div className="p-div-step0-survey-end">
              <p>You don’t want to start being active?</p>
            </div>
            <div className="enthusiastic-robot robot-div-style-step0-survey-end"></div>
            <div className="p2-div-step0-survey-end">
              <p>That’s okay;&nbsp;just think about it. </p>
            </div>
            <div className="fontawesome-icons-container">
              <div>
                <div>
                  <img alt="" className="result-icon-survey-end battery" />
                </div>
                <div>
                  <img alt="" className="result-icon-survey-end heart" />
                </div>
                <div>
                  <img alt="" className="result-icon-survey-end muscle" />
                </div>
                <div>
                  <img alt="" className="result-icon-survey-end smart" />
                </div>
                <div>
                  <img alt="" className="result-icon-survey-end smile" />
                </div>
              </div>
            </div>
            <div className="next-icon-div-step0-survey-end">
              <button>
                <FontAwesomeIcon
                  className="icon-step0-survey-end"
                  icon={faChevronRight}
                  size="2x"
                  onClick={this.props.nextStep}
                />
              </button>
            </div>
          </div>
        );
      case 1:
        return (
          <div className="flex-container-step0-survey-end bg1">
            <div className="p-div-step0-survey-end">
              <p>You’re thinking about being active!</p>
            </div>
            <div className="enthusiastic-robot robot-div-style-step0-survey-end"></div>
            <div className="p2-div-step0-survey-end">
              <p>Why is physical activity good for you? </p>
            </div>
            <div className=" fontawesome-icons-container">
              <div>
                <div>
                  <img alt="" className="result-icon-survey-end group" />
                </div>
                <div>
                  <img alt="" className="result-icon-survey-end smile" />
                </div>
                <div>
                  <img alt="" className="result-icon-survey-end muscle" />
                </div>
                <div>
                  <img alt="" className="result-icon-survey-end healthy-heart" />
                </div>
                <div>
                  <img alt="" className="result-icon-survey-end achievement" />
                </div>
              </div>
            </div>
            <div className="next-icon-div-step0-survey-end">
              <button>
                <FontAwesomeIcon
                  className="icon-step0-survey-end"
                  icon={faChevronRight}
                  size="2x"
                  onClick={this.props.nextStep}
                />
              </button>
            </div>
          </div>
        );
      case 2:
        return (
          <div className="flex-container-step0-survey-end bg1">
            <div className="p-div-step0-survey-end">
              <p>You’re ready to be active. That’s great.</p>
            </div>
            <div className="enthusiastic-robot robot-div-style-step0-survey-end"></div>
            <div className="p2-div-step0-survey-end">
              <p>What activities do you want to do? </p>
            </div>
            <div className=" fontawesome-icons-container">
              <div>
                <div>
                  <img alt="" className="result-icon-survey-end swimming" />
                </div>
                <div>
                  <img alt="" className="result-icon-survey-end cycling" />
                </div>
                <div>
                  <img alt="" className="result-icon-survey-end football" />
                </div>
                <div>
                  <img alt="" className="result-icon-survey-end walking" />
                </div>
                <div>
                  <img alt="" className="result-icon-survey-end watering" />
                </div>
              </div>
            </div>
            <div className="next-icon-div-step0-survey-end">
              <button>
                <FontAwesomeIcon
                  className="icon-step0-survey-end"
                  icon={faChevronRight}
                  size="2x"
                  onClick={this.props.nextStep}
                />
              </button>
            </div>
          </div>
        );
      case 3:
        return (
          <div className="flex-container-step0-survey-end bg1">
            <div className="p-div-step0-survey-end">
              <p>You’re doing a great job.</p>
            </div>
            <div className="enthusiastic-robot robot-div-style-step0-survey-end"></div>
            <div className="p2-div-step0-survey-end">
              <p>You’re on your way to being active.&nbsp;Keep it up!</p>
            </div>
            <div className=" fontawesome-icons-container">
              <div>
                <div>
                  <img alt="" className="result-icon-survey-end on-target" />
                </div>
                <div>
                  <img alt="" className="result-icon-survey-end reaching-your-goal" />
                </div>
                <div>
                  <img alt="" className="result-icon-survey-end calendar" />
                </div>
                <div>
                  <img alt="" className="result-icon-survey-end like" />
                </div>
                <div>
                  <img alt="" className="result-icon-survey-end confetti" />
                </div>
              </div>
            </div>
            <div className="next-icon-div-step0-survey-end">
              <button>
                <FontAwesomeIcon
                  className="icon-step0-survey-end"
                  icon={faChevronRight}
                  size="2x"
                  onClick={this.props.nextStep}
                />
              </button>
            </div>
          </div>
        );
      case 4:
        return (
          <div className="flex-container-step0-survey-end bg1">
            <div className="p-div-step0-survey-end">
              <p>Good job staying active!</p>
            </div>
            <div className="enthusiastic-robot robot-div-style-step0-survey-end"></div>
            <div className="p2-div-step0-survey-end">
              <p>Keep up the good work. You’re a good example for others.</p>
            </div>
            <div className="fontawesome-icons-container">
              <div>
                <div>
                  <img alt="" className="result-icon-survey-end confetti" />
                </div>
                <div>
                  <img alt="" className="result-icon-survey-end ribbon" />
                </div>
                <div>
                  <img alt="" className="result-icon-survey-end encouragement" />
                </div>
                <div>
                  <img alt="" className="result-icon-survey-end up-arrow" />
                </div>
                <div>
                  <img alt="" className="result-icon-survey-end cheer-up" />
                </div>
              </div>
            </div>
            <div className="next-icon-div-step0-survey-end">
              <button>
                <FontAwesomeIcon
                  className="icon-step0-survey-end"
                  icon={faChevronRight}
                  size="2x"
                  onClick={this.props.nextStep}
                />
              </button>
            </div>
          </div>
        );
      default:
        return <h1>No answer</h1>;
    }
  }
}

export default StageDeterminationReport;
