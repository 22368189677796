import React, { Component } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import "./style/step1_welcome.css";

export class Step1Welcome extends Component {
  render() {
    return (
      <div className="flex-container-step1-welcome bg2">
        <div className="p-step1-welcome">
          <p>
            Before we start,
            <br />
            just two little things...
          </p>
        </div>
        <div className="item2-step1-welcome">
          <button>
            <FontAwesomeIcon
              className="icon-step1-welcome"
              icon={faChevronRight}
              size="2x"
              onClick={this.props.nextStep}
            />
          </button>
        </div>
        <div className="shy-smile-robot robot-div-style-step1-welcome"></div>
      </div>
    );
  }
}

export default Step1Welcome;
