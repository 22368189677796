import React, { Component } from "react";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./style/step0_personal_information.css";

export class Step0PersonalInformation extends Component {
  render() {
    return (
      <div className="flex-container-step0-personal-information bg2">
        <div className="p-step0-personal-information">
          <p>
            GREAT!
            <br />
            Let's start the survey!
          </p>
        </div>
        <div className="item2-step0-personal-information">
          <button>
            <FontAwesomeIcon
              className="icon-step0-personal-information "
              icon={faChevronRight}
              size="2x"
              onClick={this.props.nextStep}
            />
          </button>
        </div>
        <div className="enthusiastic-robot  robot-div-style-step0-personal-information"></div>
      </div>
    );
  }
}

export default Step0PersonalInformation;